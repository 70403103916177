<template>
  <div class="footer-links">
    <span>All materials you can recycle: </span><MaterialsList></MaterialsList>
  </div>
</template>

<script>

import MaterialsList from "../components/MaterialsList.vue";

export default {
  name: "FooterBody",
  components: { MaterialsList }
};
</script>

<style lang="scss">

</style>